import React from 'react' 
import '../css/message.css'
const Message = () =>  {
  return(
    <div className='message-section max-w-xs xl:max-w-2xl'>
      <div className='inner'>
        <p>To our essential staff and clients, you power the heart of our company.  Thank you for standing with us to keep our doors open and our families safe. The best is yet to come!</p>
      </div>
    </div>
  )
}

export { Message }
import React from 'react'
import { Button } from './Button'
import { useStaticQuery, graphql } from 'gatsby'
import SellSheet from '../assets/pdf/SellSheet.pdf'
import styled from 'styled-components'
import Img from 'gatsby-image'
import content from '../content/footer.json'
import "../css/button.css"

// const Image = styled.img`
//   height: 26px;
//   @media (min-width: 768px) {
//     height: 28px;
//   }
//   @media (min-width: 1280px) {
//     height: 40px;
//   }
// `
// const Title = styled.h5`
// `
const FooterBottom = styled.footer`
  background-color: #e61d30;
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "SS-Logo_2020.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo2: file(relativePath: { eq: "SQF_Logo.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo3: file(relativePath: { eq: "Forestry_Initiative.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo4: file(relativePath: { eq: "glwbc-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo5: file(relativePath: { eq: "WBE_Seal.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo6: file(relativePath: { eq: "MWC_600.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo7: file(relativePath: { eq: "Tri-share.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  return(
    <>
      <div className='container'>
        <div className='flex-wrap mt-6 lg:mt-0 lg:my-6'>
          <div className='mx-auto my-4'>
            <div className='w-64 mx-auto'>
              <Img className='' fluid={data.logo.childImageSharp.fluid} alt='logo' />
            </div>
            <div className='flex justify-center mx-auto'>
              <hr className='border-primary border-t-2 my-4 w-64' />
            </div>
            <div className=''>
              <div className='flex flex-col justify-center w-40 mx-auto'>
                <p className='font-light tracking-wider inline-flex'>{content['address-1']}</p>
                <p className='font-light tracking-wider inline-flex'>{content['address-2']}</p>
                <p className='font-light tracking-wider inline-flex'>{content['address-3']}</p>
                <p className='font-light tracking-wider inline-flex'>{content['address-4']}</p>

              </div>
            </div>
          </div>
          <div className='w-full px-6 my-4 flex justify-around items-center flex-wrap'>
            <div className='w-1/3 px-4 mx-auto md:w-1/5 lg:w-1/6 self-center'>
              <a href='https://www.sqfi.com/' target='_blank' rel='noreferrer'>
              <Img className='' fluid={data.logo2.childImageSharp.fluid} alt='logo' />
                </a>
            </div>
            <div className='w-1/3 px-4 mx-auto md:w-1/5 lg:w-1/6 self-center'>
              <a href='https://forests.org/' target='_blank' rel='noreferrer'>
              <Img className='' fluid={data.logo3.childImageSharp.fluid} alt='logo' />
                </a>
            </div>
            <div className='w-1/3 px-4 mx-auto md:w-1/5 lg:w-1/6 self-center'>
              <a href='https://www.greatlakeswbc.org/' target='_blank' rel='noreferrer'>
              <Img className='' fluid={data.logo4.childImageSharp.fluid} alt='logo' />
                </a>
            </div>
            <div className='w-1/3 px-4 mx-auto md:w-1/5 lg:w-1/6 self-center'>
              <a href='https://www.wbenc.org/' target='_blank' rel='noreferrer'>
              <Img className='' fluid={data.logo5.childImageSharp.fluid} alt='logo' />
                </a>
            </div>
            <div className='w-1/3 px-4 mx-auto md:w-1/5 lg:w-1/6 self-center'>
              <a href='https://www.michigan.gov/mwc' target='_blank' rel='noreferrer'>
              <Img className='' fluid={data.logo6.childImageSharp.fluid} alt='logo' />
                </a>
            </div>
            <div className='w-1/3 px-4 mx-auto md:w-1/5 lg:w-1/6 self-center'>
              <a href='https://www.michigan.gov/mwc/initiatives/mi-tri-share-child-care' target='_blank' rel='noreferrer'>
                <Img className='' fluid={data.logo7.childImageSharp.fluid} alt='logo' />
              </a>
            </div>
          </div>
          {/* <div className='itemwrap itemwrap3'>
            {content.sections.map(({title, title_url: titleUrl, infos,}, idx) => {
              return(
                <div key={`${title}-${idx}`} className='mt-2 mx-4 lg:mx-0'>
                  <Title className='uppercase font-semibold text-black xl:text-lg'><Link to={titleUrl}>{title}</Link></Title>
                  {infos.map(( info, indx ) => <p key={indx} className='font-light text-sm my-4 md:pr-4'>{info}</p>)}
                </div>
              )
            })}
          </div> */}
        </div>
      </div>
      <FooterBottom className='p-4 text-center text-sm mx-auto font-light bg-red-400 text-white'>
        {content.trademark}
      </FooterBottom>
    </>
  )
}

export { Footer }
